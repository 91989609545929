import gql from 'graphql-tag';

const WEBSITE_FRAGMENT = gql`
  fragment WebSite on WebSite {
    address
    authSettings {
      apiHost
      authMethod
      clientId
      clientSecret
    }
    email
    phone
    domain
    enabled
    id
    lastUpdate: updatedAt
    metaDescription
    name
    parentSiteId
    queryParam
    settings
    titleTag
    version: latestVersion
  }
`;

export const GET_WEBSITES = gql`
  {
    webSites {
      ...WebSite
    }
  }
  ${WEBSITE_FRAGMENT}
`;

export const GET_WEBSITE_DOMAIN = gql`
  query WebSite($id: Uuid!) {
    webSite(filter: { id: $id }) {
      id
      domain
    }
  }
`;

export const GET_FULL_WEBSITES_LIST = gql`
  {
    webSites {
      ...WebSite
    }
  }
  ${WEBSITE_FRAGMENT}
`;

export const GET_WEBSITE = gql`
  query GetWebsite($id: Uuid!) {
    webSite(filter: { id: $id }) {
      ...WebSite
    }
  }
  ${WEBSITE_FRAGMENT}
`;

export const DELETE_WEBSITE = gql`
  mutation DeleteWebSite($id: Uuid!) {
    deleteWebSite(input: { mainSiteId: $id }) {
      success
    }
  }
`;

export const ENABLE_WEBSITE = gql`
  mutation EnableWebSite($enabled: Boolean!, $id: Uuid!) {
    enableWebSite(input: { enabled: $enabled, mainSiteId: $id }) {
      success
    }
  }
`;

export const CREATE_WEBSITE = gql`
  mutation CreateWebSite(
    $authSettings: AuthSettingsInput!
    $contactDetails: ContactDetailsInput
    $domain: DomainName
    $id: Uuid
    $name: String!
    $parentSiteId: Uuid
    $queryParam: QueryParameter
    $settings: Json!
  ) {
    createPlatform(
      input: {
        authSettings: $authSettings
        contactDetails: $contactDetails
        domain: $domain
        mainSiteId: $id
        name: $name
        parentSiteId: $parentSiteId
        queryParam: $queryParam
        settings: $settings
      }
    ) {
      success
    }
  }
`;

export const UPDATE_WEBSITE = gql`
  mutation UpdateWebSite(
    $authSettings: AuthSettingsInput!
    $contactDetails: ContactDetailsInput
    $domain: DomainName
    $id: Uuid!
    $name: String!
    $parentSiteId: Uuid
    $queryParam: QueryParameter
    $settings: Json!
  ) {
    updatePlatform(
      input: {
        authSettings: $authSettings
        contactDetails: $contactDetails
        domain: $domain
        mainSiteId: $id
        name: $name
        parentSiteId: $parentSiteId
        queryParam: $queryParam
        settings: $settings
      }
    ) {
      success
    }
  }
`;

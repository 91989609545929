/* eslint-disable import/no-cycle */

import { Result } from 'antd';
import React from 'react';
import { ResolveRoute, Route } from 'universal-router';

import { Permission } from '../apollo/query/roles/types';
import { Page } from '../config/types';
import generateUrls from '../utils/generateUrls';

interface PageModule {
  default: ResolveRoute<Page>;
}

declare module 'universal-router' {
  interface Route {
    access?: Permission;
    isProtected?: boolean;
    load?: () => Promise<PageModule>;
  }
}

function getAllRoutes(route: Route): Route[] {
  return route.children
    ? [route, ...route.children.flatMap(getAllRoutes)]
    : [route];
}

const routes: Route<Page> = {
  children: [
    {
      path: '/auth',
      load: (): Promise<PageModule> =>
        import(/* webpackChunkName: 'auth' */ './auth'),
    },
    {
      isProtected: true,
      children: [
        {
          path: '/',
          action: async ({ auth, router }): Promise<Page | null> => {
            // generateUrls does not currently work with generic UniversalRouter
            // eslint-disable-next-line
            // @ts-ignore
            const url = generateUrls(router);

            const defaultRoute = getAllRoutes(routes).find(
              route => route.access && auth.can(route.access),
            );

            return {
              redirect: url(defaultRoute?.name ?? 'platform'),
            };
          },
        },
        {
          access: Permission.ACCESS_PLATFORM,
          path: '/platform',
          name: 'platform',
          load: (): Promise<PageModule> =>
            import(/* webpackChunkName: 'platform' */ './platform'),
        },
        {
          access: Permission.ACCESS_ACCOUNTS,
          path: '/accounts',
          name: 'accounts',
          load: (): Promise<PageModule> =>
            import(/* webpackChunkName: 'accounts' */ './accounts'),
        },
        {
          access: Permission.ACCESS_OLD_TRANSLATION,
          path: '/old-translations',
          name: 'old translations',
          load: (): Promise<PageModule> =>
            import(
              /* webpackChunkName: 'translationsOld' */ './translationsOld'
            ),
        },
        {
          access: Permission.ACCESS_TRANSLATION,
          path: '/translations',
          name: 'translations',
          load: (): Promise<PageModule> =>
            import(/* webpackChunkName: 'translations' */ './translations'),
        },
        {
          access: Permission.ACCESS_LANGUAGES,
          path: '/language-config',
          name: 'language config',
          load: (): Promise<PageModule> =>
            import(/* webpackChunkName: 'languageConfig' */ './languageConfig'),
        },
        {
          access: Permission.ACCESS_CACHE,
          path: '/cache',
          name: 'cache',
          load: (): Promise<PageModule> =>
            import(/* webpackChunkName: 'cache' */ './cache'),
        },
        {
          access: Permission.ACCESS_DELIVERY_METHODS,
          path: '/delivery-methods',
          name: 'delivery methods',
          load: (): Promise<PageModule> =>
            import(
              /* webpackChunkName: 'delivery-methods' */ './deliveryMethods'
            ),
        },
        {
          access: Permission.ACCESS_GIFT_VOUCHER,
          path: '/gift-vouchers',
          name: 'gift vouchers',
          load: (): Promise<PageModule> =>
            import(/* webpackChunkName: 'giftVouchers' */ './giftVouchers'),
        },
        {
          path: '/:siteId',
          children: [
            {
              access: Permission.ACCESS_OUTER_FRAME,
              path: '/outer-frame',
              name: 'outer frame',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'outer-frame' */ './outerFrame'),
            },
            {
              access: Permission.ACCESS_HOMEPAGE,
              path: '/homepage',
              name: 'homepage',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'homepage' */ './homepage'),
            },
            {
              access: Permission.ACCESS_HOMEPAGE,
              path: '/header-menu',
              name: 'headerMenu',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'headerMenu' */ './headerMenu'),
            },
            {
              access: Permission.ACCESS_SERIES,
              path: '/products',
              name: 'products',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'products' */ './products'),
            },
            {
              access: Permission.ACCESS_CUSTOMERS,
              path: '/customer-management',
              name: 'customer management',
              load: (): Promise<PageModule> =>
                import(
                  /* webpackChunkName: 'customer-management' */ './customerManagement'
                ),
            },
            {
              access: Permission.ACCESS_LOGIN_AS_CUSTOMER,
              path: '/login-as-customer',
              name: 'login as customer',
              load: (): Promise<PageModule> =>
                import(
                  /* webpackChunkName: 'login-as-customer' */ './loginAsCustomer'
                ),
            },
            {
              access: Permission.ACCESS_CALENDAR_RECORD,
              path: '/calendar',
              name: 'calendar',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'calendar' */ './calendar'),
            },
            {
              access: Permission.ACCESS_REDIRECT_RULE,
              path: '/redirect-rules',
              name: 'redirect rules',
              load: (): Promise<PageModule> =>
                import(
                  /* webpackChunkName: 'redirect-rules' */ './redirectRules'
                ),
            },
            {
              access: Permission.ACCESS_FOOTER_CONTROL,
              path: '/footer-control',
              name: 'footer control',
              load: (): Promise<PageModule> =>
                import(
                  /* webpackChunkName: 'footer-control' */ './footerControl'
                ),
            },
            {
              access: Permission.ACCESS_CHECKOUT,
              path: '/mailing',
              name: 'mailing',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'mailing' */ './mailing'),
            },
            {
              access: Permission.ACCESS_ACCOUNTS,
              path: '/csvExport',
              name: 'csvExport',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'csvExport' */ './csvExport'),
            },
            {
              access: Permission.ACCESS_LANDING_PAGES,
              path: '/site-map',
              name: 'siteMap',
              load: (): Promise<PageModule> =>
                import(/* webpackChunkName: 'siteMap' */ './siteMap'),
            },
            {
              access: Permission.ACCESS_PLATFORM,
              path: '/checkout-settings',
              name: 'checkout settings',
              load: (): Promise<PageModule> =>
                import(
                  /* webpackChunkName: 'checkoutSettings' */ './checkoutSettings'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '(.*)',
      action: (): Page => ({
        component: (
          <Result
            status="404"
            subTitle="Sorry, the page you visited does not exist."
            title="404"
          />
        ),
      }),
    },
  ],
  async action({ next }) {
    const route = await next();

    return route;
  },
};

export default routes;

import React, { Dispatch } from 'react';

import { FullWebSite } from '../apollo/query/webSites/types';

export interface WebSiteContextInterface {
  setWebSite?: Dispatch<FullWebSite>;
  webSite: FullWebSite;
}

export default React.createContext<WebSiteContextInterface | null>(null);

import { Result } from 'antd';
import { Action, Location, Update } from 'history';
import qs from 'query-string';
import React from 'react';
import { Root, createRoot } from 'react-dom/client';
import Cookies from 'universal-cookie';
import 'antd/dist/reset.css';

import apolloClient from './apollo';
import { GetWebSiteData, GetWebSiteVars } from './apollo/query/webSites/types';
import { GET_WEBSITE, GET_WEBSITES } from './apollo/query/webSites/webSites';
import Auth from './config/auth';
import history from './config/history';
import router from './config/router';
import * as serviceWorker from './config/serviceWorker';
import getDefaultPlatform from './utils/getDefaultPlatform';
import App from './views/App/App';

import './index.css';

const cookies = new Cookies();
const auth = new Auth(cookies);

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const scrollPositionsHistory: {
  [pathname: string]: { scrollX: number; scrollY: number };
} = {};

function restoreScrollPosition(location: Location): void {
  let scrollX = 0;
  let scrollY = 0;

  const pos = location.key ? scrollPositionsHistory[location.key] : null;

  if (pos) {
    scrollX = pos.scrollX;
    scrollY = pos.scrollY;
  } else {
    const targetHash = location.hash.substring(1);

    if (targetHash) {
      const target = document.getElementById(targetHash);

      if (target) {
        scrollY = window.pageYOffset + target.getBoundingClientRect().top;
      }
    }
  }

  // Restore the scroll position if it was saved into the state
  // or scroll to the given #hash anchor
  // or scroll to top of the page
  window.scrollTo(scrollX, scrollY);
}

function onRenderComplete(location: Location, isInitialRender: boolean): void {
  if (isInitialRender) {
    restoreScrollPosition(location);
  }
}

let currentLocation = history.location;
let root: Root | null = null;

// Re-render the app when window.location changes
async function onLocationChange(update: Update): Promise<void> {
  const { location, action } = update;

  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.pathname] = {
    scrollX: window.scrollX,
    scrollY: window.scrollY,
  };

  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.pathname];
  }

  currentLocation = location;

  const querySearch = qs.parse(location.search);

  try {
    await auth.refresh();
    await auth.getMyAccount();

    const page = await router.resolve({
      auth,
      pathname: location.pathname,
      query: querySearch,
    });

    if (page) {
      if (page.redirect) {
        history.replace(page.redirect);

        return;
      }

      const container = document.getElementById('root');

      if (!root && container) {
        root = createRoot(container);
      }

      const getInitialWebSite = async () => {
        try {
          const initialSiteId =
            page.params?.siteId || querySearch.siteId || cookies.get('siteId');

          if (!initialSiteId) {
            const { data: webSitesList } = await apolloClient.query({
              query: GET_WEBSITES,
            });

            return (
              getDefaultPlatform(webSitesList.webSites) ||
              webSitesList.webSites[0]
            );
          }

          const { data } = await apolloClient.query<
            GetWebSiteData,
            GetWebSiteVars
          >({ query: GET_WEBSITE, variables: { id: initialSiteId } });

          return data.webSite;
        } catch (e) {
          console.error(e);

          // In case if there is incorrect siteId cookie setup
          cookies.remove('siteId');

          return null;
        }
      };

      if (root) {
        if (!auth.getState().isAuthenticated) {
          // Auth form
          root.render(page.component);
        } else {
          const initialWebSite = await getInitialWebSite();

          root.render(
            initialWebSite ? (
              <App
                auth={auth}
                callback={() => onRenderComplete(currentLocation, !action)}
                initialWebSite={initialWebSite}
                page={page}
              />
            ) : (
              <Result
                status="404"
                subTitle="Sorry, the page you visited does not exist."
                title="404"
              />
            ),
          );
        }
      }
    }
  } catch (error) {
    // if (__DEV__) throw error;

    console.error(error);
  }
}

history.listen(onLocationChange);
onLocationChange({ location: currentLocation, action: Action.Pop });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

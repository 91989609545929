export enum RoleType {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MARKETING_MANAGER = 'ROLE_MARKETING_MANAGER',
  ROLE_CIRCUIT_MANAGER = 'ROLE_CIRCUIT_MANAGER',
  ROLE_TRANSLATOR = 'ROLE_TRANSLATOR',
}

export enum Permission {
  ACCESS_ACCOUNTS = 'ACCESS_ACCOUNTS',
  ACCESS_CACHE = 'ACCESS_CACHE',
  ACCESS_CALENDAR_RECORD = 'ACCESS_CALENDAR_RECORD',
  ACCESS_CHECKOUT = 'ACCESS_CHECKOUT',
  ACCESS_CURRENCY_EXCHANGE = 'ACCESS_CURRENCY_EXCHANGE',
  ACCESS_CUSTOMERS = 'ACCESS_CUSTOMERS', // deprecated
  ACCESS_CUSTOMERS_MUTATION = 'ACCESS_CUSTOMERS_MUTATION',
  ACCESS_CUSTOMERS_QUERY = 'ACCESS_CUSTOMERS_QUERY',
  ACCESS_DELIVERY_METHODS = 'ACCESS_DELIVERY_METHODS',
  ACCESS_FOOTER_CONTROL = 'ACCESS_FOOTER_CONTROL',
  ACCESS_GIFT_VOUCHER = 'ACCESS_GIFT_VOUCHER',
  ACCESS_HOMEPAGE = 'ACCESS_HOMEPAGE', // deprecated
  ACCESS_HOME_PAGE_MUTATION = 'ACCESS_HOME_PAGE_MUTATION',
  ACCESS_HOME_PAGE_QUERY = 'ACCESS_HOME_PAGE_QUERY',
  ACCESS_LANDING_PAGES = 'ACCESS_LANDING_PAGES',
  ACCESS_LANGUAGES = 'ACCESS_LANGUAGES',
  ACCESS_LOGIN_AS_CUSTOMER = 'ACCESS_LOGIN_AS_CUSTOMER',
  ACCESS_MEDIA = 'ACCESS_MEDIA',
  ACCESS_MEDIA_MODULE = 'ACCESS_MEDIA_MODULE',
  ACCESS_OLD_TRANSLATION = 'ACCESS_OLD_TRANSLATION',
  ACCESS_OUTER_FRAME = 'ACCESS_OUTER_FRAME',
  ACCESS_OUTER_FRAME_HEAD_BANNER_MUTATION = 'ACCESS_OUTER_FRAME_HEAD_BANNER_MUTATION',
  ACCESS_OUTER_FRAME_SKIN_MUTATION = 'ACCESS_OUTER_FRAME_SKIN_MUTATION',
  ACCESS_PAGE_LAYOUT = 'ACCESS_PAGE_LAYOUT',
  ACCESS_PAGE_LAYOUT_MUTATION = 'ACCESS_PAGE_LAYOUT_MUTATION',
  ACCESS_PAGE_LAYOUT_QUERY = 'ACCESS_PAGE_LAYOUT_QUERY',
  ACCESS_PLATFORM = 'ACCESS_PLATFORM',
  ACCESS_PRODUCT_INFO_MUTATION = 'ACCESS_PRODUCT_INFO_MUTATION',
  ACCESS_PRODUCT_QUERY = 'ACCESS_PRODUCT_QUERY',
  ACCESS_PROMO_BANNER_MUTATION = 'ACCESS_PROMO_BANNER_MUTATION',
  ACCESS_RACE_EVENT = 'ACCESS_RACE_EVENT',
  ACCESS_REDIRECT_RULE = 'ACCESS_REDIRECT_RULE',
  ACCESS_REFERRAL_MODULE_MUTATION = 'ACCESS_REFERRAL_MODULE_MUTATION',
  ACCESS_RELATE_IMAGE = 'ACCESS_RELATE_IMAGE',
  ACCESS_SERIES = 'ACCESS_SERIES',
  ACCESS_TICKETS = 'ACCESS_TICKETS',
  ACCESS_TRANSLATION = 'ACCESS_TRANSLATION',
  APPROVE_RACE_EVENT_AS_ADMIN = 'APPROVE_RACE_EVENT_AS_ADMIN',
  APPROVE_RACE_EVENT_AS_CIRCUIT_MANAGER = 'APPROVE_RACE_EVENT_AS_CIRCUIT_MANAGER',
  APPROVE_RACE_EVENT_AS_MARKETING_MANAGER = 'APPROVE_RACE_EVENT_AS_MARKETING_MANAGER',
  UNAPPROVE_RACE_EVENT_AS_ADMIN = 'UNAPPROVE_RACE_EVENT_AS_ADMIN',
  UNAPPROVE_RACE_EVENT_AS_CIRCUIT_MANAGER = 'UNAPPROVE_RACE_EVENT_AS_CIRCUIT_MANAGER',
  UNAPPROVE_RACE_EVENT_AS_MARKETING_MANAGER = 'UNAPPROVE_RACE_EVENT_AS_MARKETING_MANAGER',
}

export interface Role {
  id: RoleType;
  name: string;
}

export interface RoleWithPermissions extends Role {
  permissions: { name: Permission }[];
}

export interface GetRoleList {
  roles: Omit<Role, 'permissions'>[];
}

export interface SetRolesVars {
  roleIds: RoleType[];
  userId: string;
}

import { WebSite } from '../apollo/query/webSites/types';

export default (webSites: WebSite[] | undefined): WebSite | undefined =>
  webSites?.find(site => {
    const { domain } = site;

    return (
      domain &&
      !site.parentSiteId &&
      [
        'testing-webapp.motorsporttickets.com',
        'staging-webapp.motorsporttickets.com',
        'motorsporttickets.com',
      ].includes(domain)
    );
  });

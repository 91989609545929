import { DisplayPolicy, Paged, Pagination } from '../../types';
import { Duration } from '../durations/types';
import { ImageData } from '../images/types';
import { RaceEventSeriesSlug } from '../raceEvent/types';
import { AdmissionTag, Availability, ProductType } from '../tickets/types';

export enum VehicleType {
  BOAT = 'BOAT',
  BUS = 'BUS',
  CAR = 'CAR',
  COACH = 'COACH',
  HELICOPTER = 'HELICOPTER',
  TRAIN = 'TRAIN',
}

export interface Travel {
  admission: AdmissionTag;
  availability: Availability;
  availableQuantity?: number;
  dateFrom: string;
  dateTo: string;
  description?: string;
  displayPolicy: DisplayPolicy;
  duration: Duration;
  externalName: string;
  icons?: ImageData[];
  id: string;
  images: ImageData[];
  minimumQuantity: number;
  name?: string;
  price: number;
  searchTags?: string;
  seoTags?: string;
  tier?: {
    title: string;
  };
  type: ProductType.TRAVEL;
  vehicleType: VehicleType;
}

export interface GetTravelList {
  travelList: Paged<Travel>;
}

export interface GetTravelData {
  travel: {
    id: string;
    name: string;
    raceEvent: RaceEventSeriesSlug;
  };
}

export interface GetTravelSlugs {
  travel: {
    displayPolicy: DisplayPolicy;
    raceEvent: RaceEventSeriesSlug;
    tierId: string | null;
  };
}

export interface GetTravelListVars {
  pagination?: Pagination;
  raceEventId?: string;
  tierId: string | null;
}

export interface GetTravelDataVars {
  currency?: string;
  id: string;
}

export interface UpdateTravelVars {
  admission?: AdmissionTag;
  description?: string;
  displayPolicy?: DisplayPolicy;
  durationId?: string;
  iconIds?: string[];
  id: string;
  imageIds?: string[];
  name?: string;
  searchTags?: string;
  seoTags?: string;
  tierId?: string;
  vehicleType?: VehicleType;
}

import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import React, { useMemo, useState } from 'react';

import apolloClient from '../../apollo';
import { FullWebSite } from '../../apollo/query/webSites/types';
import antConfig from '../../config/antConfig';
import Auth from '../../config/auth';
import { AuthProvider } from '../../config/authContext';
import { Page } from '../../config/types';
import WebSiteContext from '../../config/webSiteContext';

interface AppProps {
  auth: Auth;
  callback?: () => void;
  initialWebSite: FullWebSite;
  page: Page;
}

const App: React.FC<AppProps> = ({ auth, page, callback, initialWebSite }) => {
  const [webSite, setWebSite] = useState<FullWebSite>(initialWebSite);

  const webSiteValues = useMemo(
    () => ({
      webSite,
      setWebSite,
    }),
    [webSite],
  );

  return (
    <div ref={callback} className="App">
      <WebSiteContext.Provider value={webSiteValues}>
        <ConfigProvider {...antConfig}>
          <ApolloProvider client={apolloClient}>
            <AuthProvider value={auth}>{page.component}</AuthProvider>
          </ApolloProvider>
        </ConfigProvider>
      </WebSiteContext.Provider>
    </div>
  );
};

export default App;

import EventEmitter from 'events';

import qs from 'query-string';
import Cookies from 'universal-cookie';

import client from '../apollo';
import { GET_MY_ACCOUNT } from '../apollo/query/accounts/accounts';
import { GetMyAccountData } from '../apollo/query/accounts/types';
import { Permission } from '../apollo/query/roles/types';

import config from './config';
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from './constants';

const { CLIENT_ID, CLIENT_SECRET, API_HOST } = config;

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface LoginParams {
  password: string;
  remember?: boolean;
  username: string;
}

interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  remember?: boolean;
  scope: unknown;
  token_type: string;
}

export default class Auth extends EventEmitter {
  private isAuthenticated = false;

  private isLoading = false;

  private permissions: Permission[] = [];

  static async request(body: Record<string, unknown>): Promise<AuthResponse> {
    const response = await fetch(`${API_HOST}/oauth/v2/token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(body),
    });

    const data = await response.json();

    if (!response.ok) throw data;

    return data;
  }

  constructor(private cookies: Cookies) {
    super();
  }

  getState = (): AuthState => {
    return {
      isAuthenticated: this.isAuthenticated,
      isLoading: this.isLoading,
    };
  };

  login = async (params: LoginParams): Promise<void> => {
    try {
      this.isLoading = true;
      this.emit('update');

      const { username, password, remember } = params;

      const tokens = await Auth.request({
        username,
        password,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'password',
      });

      this.setTokens({ ...tokens, remember });
      this.isAuthenticated = true;
    } finally {
      this.isLoading = false;
      this.emit('update');
    }
  };

  public refresh = async (isForcedRefresh = false): Promise<void> => {
    const accessToken = this.cookies.get(COOKIE_ACCESS_TOKEN);
    const refreshToken = this.cookies.get(COOKIE_REFRESH_TOKEN);

    if (accessToken && !isForcedRefresh) {
      this.isAuthenticated = true;
    } else if (refreshToken) {
      try {
        this.isLoading = true;
        this.emit('update');

        const tokens = await Auth.request({
          refresh_token: refreshToken,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          grant_type: 'refresh_token',
        });

        this.setTokens(tokens);
        this.isAuthenticated = true;
      } catch (err) {
        this.logout();
      } finally {
        this.isLoading = false;
        this.emit('update');
      }
    }
  };

  logout = (): void => {
    client.clearStore();
    this.cookies.remove(COOKIE_ACCESS_TOKEN);
    this.cookies.remove(COOKIE_REFRESH_TOKEN);
    this.isAuthenticated = false;
    this.permissions = [];
    this.emit('update');
  };

  getMyAccount = async (): Promise<void> => {
    if (this.isAuthenticated) {
      this.isLoading = true;
      this.emit('update');

      const { data } = await client.query<GetMyAccountData>({
        query: GET_MY_ACCOUNT,
      });

      this.permissions = data.myAccount.roles.flatMap(role =>
        role.permissions.map(permission => permission.name),
      );

      this.isLoading = false;
      this.emit('update');
    }
  };

  can = (action: Permission): boolean => this.permissions.includes(action);

  private setTokens = ({
    access_token: accessToken,
    refresh_token: refreshToken,
    expires_in: maxAge,
    remember,
  }: AuthResponse): void => {
    this.cookies.set(COOKIE_ACCESS_TOKEN, accessToken, {
      maxAge,
      path: '/',
    });

    if (remember || this.cookies.get(COOKIE_REFRESH_TOKEN))
      this.cookies.set(COOKIE_REFRESH_TOKEN, refreshToken, { path: '/' });
  };
}

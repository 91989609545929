import React, { useContext, useEffect, useReducer } from 'react';
import Cookies from 'universal-cookie';

import Auth from './auth';

const AuthContext = React.createContext<Auth>(new Auth(new Cookies()));

export const AuthProvider = AuthContext.Provider;

export const useAuth = (): Auth => {
  const auth = useContext(AuthContext);
  const [, forceUpdate] = useReducer(s => s + 1, 0);

  useEffect(() => {
    auth.addListener('update', forceUpdate);

    return (): void => {
      auth.removeListener('update', forceUpdate);
    };
  }, [auth]);

  return auth;
};

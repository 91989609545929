/* eslint-disable no-template-curly-in-string */
const typeTemplate = '${label} is not a valid ${type}';

export default {
  form: {
    validateMessages: {
      required: '${label} field is required',
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate,
      },
      string: {
        len: '${label} must be exactly ${len} characters',
        min: '${label} must be at least ${min} characters',
        max: '${label} cannot be longer than ${max} characters',
        range: '${label} must be between ${min} and ${max} characters',
      },
      number: {
        len: '${label} must equal ${len}',
        min: '${label} cannot be less than ${min}',
        max: '${label} cannot be greater than ${max}',
        range: '${label} must be between ${min} and ${max}',
      },
    },
  },
};

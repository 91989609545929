import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { message as antdMessage } from 'antd';
import qs from 'query-string';
import Cookies from 'universal-cookie';

import Auth from '../config/auth';
import config from '../config/config';
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from '../config/constants';
import history from '../config/history';

const linkOptions = {
  uri: config.API_HOST,
};

const cookies = new Cookies();
const { location } = history;

const isAuthError = (error: ServerError) => {
  return (
    error &&
    error.statusCode &&
    error.statusCode === 401 &&
    location.pathname !== '/auth'
  );
};

const client = new ApolloClient({
  link: ApolloLink.from([
    new RetryLink({
      delay: {
        initial: 10,
        max: 2,
        jitter: true,
      },
      attempts: {
        max: 2,
        retryIf: async error => {
          if (isAuthError(error) && cookies.get(COOKIE_REFRESH_TOKEN)) {
            const auth = new Auth(cookies);

            await auth.refresh();

            return true;
          }

          return false;
        },
      },
    }),
    new ApolloLink((operation, forward) => {
      operation.setContext(
        (
          context: Record<string, unknown> & {
            headers: Record<string, string>;
          },
        ) => ({
          headers: {
            ...context.headers,
            Authorization: `Bearer ${cookies.get(COOKIE_ACCESS_TOKEN)}`,
          },
        }),
      );

      return forward(operation);
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
          );

          antdMessage.error(message);
        });
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError.message}`);

        if (
          isAuthError(networkError as ServerError) &&
          !cookies.get(COOKIE_REFRESH_TOKEN)
        ) {
          history.push(
            `/auth?${qs.stringify({
              redirect: `${location.pathname}${location.search}`,
            })}`,
          );
        }
      }
    }),
    new HttpLink(linkOptions),
  ]),
  cache: new InMemoryCache({
    possibleTypes: {
      Widget: [
        'AlertBannerWidget',
        'BlogPageWidget',
        'BookingsWidget',
        'CardsWidget',
        'CarouselWidget',
        'CtaBoxWidget',
        'FeaturedProductWidget',
        'GenericIframeWidget',
        'GetInspiredWidget',
        'GiftVoucherWidget',
        'GoldenTicketsWidget',
        'GoogleAdBannerWidget',
        'HeaderMediaWidget',
        'InDepthWidget',
        'ItineraryWidget',
        'LatestNewsWidget',
        'MapWidget',
        'MediaWidget',
        'NotifyMeWidget',
        'OtherPagesWidget',
        'ProductGridWidget',
        'RaceEventTicketInfoWidget',
        'RecommendationsWidget',
        'ReferralWidget',
        'SpacerWidget',
        'StaticBannerWidget',
        'TextWidget',
        'ThirdPartyScriptWidget',
        'TicketOptionsWidget',
        'TicketsWidget',
        'WhyShopWidget',
        'Widget',
      ],
    },
  }),
});

export default client;

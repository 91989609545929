import gql from 'graphql-tag';

const ACCOUNT_FRAGMENT = gql`
  fragment Account on Account {
    id
    mainSiteIds
    roles {
      id
      name
    }
    username
  }
`;

export const GET_MY_ACCOUNT = gql`
  query GetMyAccount($pass: String) {
    myAccount(filter: { pass: $pass }) {
      id
      mainSiteIds
      roles {
        id
        name
        permissions {
          name
        }
      }
      username
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query GetAccounts($mainSiteId: Uuid) {
    accounts(filter: { mainSiteId: $mainSiteId }) {
      ...Account
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const GET_ACCOUNT = gql`
  query GetAccount($id: Uuid, $username: String) {
    account(filter: { id: $id, username: $username }) {
      ...Account
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount(
    $id: Uuid
    $mainSiteIds: [Uuid!]
    $password: String!
    $roleIds: [RoleEnum!]!
    $username: String!
  ) {
    createAccount(
      input: {
        id: $id
        mainSiteIds: $mainSiteIds
        password: $password
        roleIds: $roleIds
        username: $username
      }
    ) {
      success
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount(
    $id: Uuid!
    $mainSiteIds: [Uuid!]
    $roleIds: [RoleEnum!]
    $username: String
  ) {
    updateAccount(
      input: {
        id: $id
        mainSiteIds: $mainSiteIds
        roleIds: $roleIds
        username: $username
      }
    ) {
      success
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id: Uuid!) {
    deleteAccount(input: { id: $id }) {
      success
    }
  }
`;

import { Result } from 'antd';
import qs from 'query-string';
import React from 'react';
import UniversalRouter, { RouterOptions } from 'universal-router';

import routes from '../routes';
import generateUrls from '../utils/generateUrls';

import RouteContext from './routeContext';
import { Page } from './types';

const routerOptions: RouterOptions<Page> = {
  resolveRoute(context, params) {
    if (context.route.isProtected && !context.auth.getState().isAuthenticated) {
      return {
        redirect: `/auth?${qs.stringify({
          redirect: `${context.pathname}?${qs.stringify(context.query)}`,
        })}`,
        from: context.pathname,
      };
    }

    if (context.route.access && !context.auth.can(context.route.access)) {
      return {
        component: (
          <Result status="403" subTitle="Access denied." title="403" />
        ),
      };
    }

    if (context.route.load) {
      return context.route.load().then(async action => {
        const page = await action.default(context, params);
        const { query, route } = context;
        const { name } = route;

        if (name && page && page.component) {
          return {
            ...page,
            params,
            component: (
              <RouteContext.Provider
                value={{
                  name,
                  query,
                  params,
                }}
              >
                {page.component}
              </RouteContext.Provider>
            ),
          };
        }

        return page;
      });
    }

    if (context.route.action) {
      return context.route.action(context, params);
    }

    return undefined;
  },
};

const router = new UniversalRouter(routes, routerOptions);

export default router;

// generateUrls does not currently work with generic UniversalRouter
// eslint-disable-next-line
// @ts-ignore
export const url = generateUrls(router, {
  stringifyQueryParams: qs.stringify,
});

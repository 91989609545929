import { Rule } from 'antd/es/form';

import { ParkingType } from '../apollo/query/parking/types';
import { Demographic, TicketDuration } from '../apollo/query/tickets/types';
import { VehicleType } from '../apollo/query/travel/types';

/* Cookie names */
export const COOKIE_ACCESS_TOKEN = 'accessToken';
export const COOKIE_REFRESH_TOKEN = 'refreshToken';

/* Formats */
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

/* Ticket Durations */
export const TICKET_DURATIONS: TicketDuration[] = [
  TicketDuration.DURATION_1_DAY,
  TicketDuration.DURATION_2_DAYS,
  TicketDuration.DURATION_3_DAYS,
  TicketDuration.DURATION_4_DAYS,
  TicketDuration.DURATION_5_DAYS,
  TicketDuration.DURATION_6_DAYS,
  TicketDuration.DURATION_7_DAYS,
];

/* Ticket Demographics */
export const DEMOGRAPHICS: Demographic[] = [
  Demographic.ADULT,
  Demographic.BABY,
  Demographic.CHILD,
  Demographic.CONCESSION,
  Demographic.DISABLED,
  Demographic.FEMALE,
  Demographic.MALE,
  Demographic.PACKAGE,
  Demographic.SENIOR,
  Demographic.STUDENT,
  Demographic.TEENAGER,
  Demographic.WHEELCHAIR,
  Demographic.WHEELCHAIR_COMPANION,
];

/* Parking Type */
export const PARKING_TYPE: ParkingType[] = [
  ParkingType.BUS,
  ParkingType.CAR,
  ParkingType.COACH,
  ParkingType.MOTORBIKE,
  ParkingType.MOTORHOME,
];

export const VEHICLE_TYPE: VehicleType[] = [
  VehicleType.BOAT,
  VehicleType.BUS,
  VehicleType.CAR,
  VehicleType.COACH,
  VehicleType.HELICOPTER,
  VehicleType.TRAIN,
];

/* Custom validation rules */
export const VALIDATION_RULE_SLUG_PATTERN: Rule = {
  pattern: /^[a-z0-9_-]+$/,
  message:
    'Slug can only contain lowercase alphanumeric characters and "_" or "-"',
};

export const VALIDATION_URL_PATTERN: Rule = {
  pattern: /^((https?:\/\/|\/)|mailto:).+/,
  message:
    'Must be a valid url. Example: /f1/monaco or https://motorsporttickets.com  (opens new tab)',
};

/* Temporary data */
export const currencies = ['AUD', 'CAD', 'USD', 'EUR', 'GBP', 'SAR'];

import { DisplayPolicy, Paged, Pagination } from '../../types';
import { Duration } from '../durations/types';
import { ImageData } from '../images/types';
import { RaceEventSeriesSlug } from '../raceEvent/types';
import { AdmissionTag, Availability, ProductType } from '../tickets/types';

export enum ParkingType {
  BUS = 'BUS',
  CAR = 'CAR',
  COACH = 'COACH',
  MOTORBIKE = 'MOTORBIKE',
  MOTORHOME = 'MOTORHOME',
}

export interface Parking {
  admission: AdmissionTag;
  availability: Availability;
  availableQuantity?: number;
  dateFrom: string;
  dateTo: string;
  description?: string;
  displayPolicy: DisplayPolicy;
  duration: Duration;
  externalName: string;
  icons?: ImageData[];
  id: string;
  images: ImageData[];
  minimumQuantity: number;
  name?: string;
  parkingType: ParkingType;
  price: number;
  searchTags?: string;
  seoTags?: string;
  tier?: {
    title: string;
  };
  type: ProductType.PARKING;
}

export interface GetParkingList {
  parkingList: Paged<Parking>;
}

export interface GetParkingListVars {
  pagination?: Pagination;
  raceEventId?: string;
  tierId: string | null;
}

export interface UpdateParkingVars {
  admission?: AdmissionTag;
  description?: string;
  displayPolicy?: DisplayPolicy;
  durationId?: string;
  iconIds?: string[];
  id: string;
  imageIds?: string[];
  name?: string;
  parkingType?: ParkingType;
  searchTags?: string;
  seoTags?: string;
  tierId?: string;
}

export interface GetParkingData {
  parking: {
    id: string;
    name: string;
    raceEvent: RaceEventSeriesSlug;
  };
}

export interface GetParkingDataVars {
  currency?: string;
  id: string;
}

export interface GetParkingSlugs {
  parking: {
    displayPolicy: DisplayPolicy;
    raceEvent: RaceEventSeriesSlug;
    tierId: string | null;
  };
}

import { DisplayPolicy, Paged, Pagination } from '../../types';
import { Duration } from '../durations/types';
import { ImageData } from '../images/types';
import { RaceEventSaleStatus, RaceEventSeriesSlug } from '../raceEvent/types';

export enum AdmissionTag {
  CAMPING = 'CAMPING',
  EXCLUSIVES = 'EXCLUSIVES',
  GENERAL = 'GENERAL',
  GRANDSTAND = 'GRANDSTAND',
  HOSPITALITY = 'HOSPITALITY',
  HOTEL = 'HOTEL',
  PACKAGE = 'PACKAGE',
  PADDOCK_CLUB = 'PADDOCK_CLUB',
  PARKING = 'PARKING',
  TRANSFER = 'TRANSFER',
}

export enum FeatureTag {
  ACTION_AREA = 'ACTION_AREA',
  ADDED_VALUE = 'ADDED_VALUE',
  COVERED_STAND = 'COVERED_STAND',
  DISABLED_ACCESS = 'DISABLED_ACCESS',
  GIANT_SCREEN_VISIBLE = 'GIANT_SCREEN_VISIBLE',
  SEATED_LOCATION = 'SEATED_LOCATION',
}

export enum TicketDuration {
  DURATION_1_DAY = '1',
  DURATION_2_DAYS = '2',
  DURATION_3_DAYS = '3',
  DURATION_4_DAYS = '4',
  DURATION_5_DAYS = '5',
  DURATION_6_DAYS = '6',
  DURATION_7_DAYS = '7',
}

export enum Availability {
  AWAITING_ON_SALE = 'AWAITING_ON_SALE',
  EXTERNAL = 'EXTERNAL',
  HIDDEN = 'HIDDEN',
  ON_REQUEST = 'ON_REQUEST',
  ON_SALE_HIGH = 'ON_SALE_HIGH',
  ON_SALE_LOW = 'ON_SALE_LOW',
  ON_SALE_VERY_LOW = 'ON_SALE_VERY_LOW',
  PRIVATE = 'PRIVATE',
  SOLD_OUT = 'SOLD_OUT',
  UNKNOWN = 'UNKNOWN',
}

export enum Demographic {
  ADULT = 'ADULT',
  BABY = 'BABY',
  CHILD = 'CHILD',
  CONCESSION = 'CONCESSION',
  DISABLED = 'DISABLED',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  PACKAGE = 'PACKAGE',
  SENIOR = 'SENIOR',
  STUDENT = 'STUDENT',
  TEENAGER = 'TEENAGER',
  WHEELCHAIR = 'WHEELCHAIR',
  WHEELCHAIR_COMPANION = 'WHEELCHAIR_COMPANION',
}

export enum ProductType {
  CAMPSITE = 'CAMPSITE',
  PARKING = 'PARKING',
  TICKET = 'TICKET',
  TRAVEL = 'TRAVEL',
}

export interface BaseTicket {
  id: string;
  name: string;
  price: number;
  raceEvent: RaceEventSeriesSlug;
}

export interface Ticket {
  admission: AdmissionTag;
  ageFrom: number;
  ageTo: number;
  availability: Availability;
  demographic: Demographic;
  description: string;
  displayPolicy: DisplayPolicy;
  duration: Duration;
  externalName: string;
  features: FeatureTag[];
  icons: ImageData[];
  id: string;
  images: ImageData[];
  name: string;
  price: number;
  raceEventId: string;
  searchTags: string;
  seoTags: string;
  ticketFrom: string;
  ticketTo: string;
  tier?: {
    title: string;
  };
  type: ProductType.TICKET;
}

export interface GetTicketList {
  tickets: Paged<Ticket>;
}

export interface GetTicketData {
  ticket: BaseTicket;
}

export interface GetTicketListVars {
  pagination?: Pagination;
  raceEventId?: string;
  tierId: string | null;
}

export interface GetTicketDataVars {
  currency?: string;
  id: string;
}

export interface SearchItem {
  __typename: string;
  id: string;
  name: string;
}

export interface SearchRaceEventItem extends SearchItem {
  saleStatus: RaceEventSaleStatus;
  season: string;
  series: SearchItem;
}

export interface SearchTicketsItem extends SearchItem {
  availability: Availability;
  price: number;
  raceEvent: SearchRaceEventItem;
  tier?: {
    ticketGroup: {
      title: string;
      ticketCategory: {
        title: string;
      };
    };
    title: string;
  };
}

export interface SearchTicketsData {
  tickets: Paged<SearchTicketsItem>;
}

export interface SearchTicketsVars {
  availabilities?: Availability[];
  pagination?: Pagination;
  raceEventId?: string;
  raceEventName?: string;
  seriesId?: string;
  siteId?: string;
  ticketName?: string;
}

export interface ResyncTicketsVars {
  raceEventId?: string;
}

export interface UpdateTicketVars {
  admission?: AdmissionTag;
  ageFrom?: number | null;
  ageTo?: number | null;
  demographic?: Demographic;
  description?: string;
  displayPolicy?: DisplayPolicy;
  durationId?: string;
  features?: FeatureTag[];
  groupId?: string;
  iconIds?: string[];
  id: string;
  imageIds?: string[];
  name?: string;
  searchTags?: string;
  seoTags?: string;
  tierId?: string;
}

export interface UpdateAffiliateTicketVars {
  admission?: AdmissionTag;
  id: string;
  siteId: string;
}

export interface GetTicketSlugs {
  ticket: {
    displayPolicy: DisplayPolicy;
    raceEvent: RaceEventSeriesSlug;
    tierId: string | null;
  } | null;
}
